
#__next
    height: 100%
    position: absolute
    width: 100%

.html
    height: 100%
    width: 100%

.body
    padding: 0
    margin: 0
    height: 100%
    *
        box-sizing: border-box

    table
        border-collapse: collapse
        border-spacing: 0

    input, select, textarea
        width: 100%
        display: block
        padding: 1px 9px
        height: 32px
        font-size: 14px
        line-height: 1.42857
        color: #767676
        background-color: #fff
        border: 1px solid #cbd5dd
        border-radius: 0
