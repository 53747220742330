@import "@as-pl/ui/globals.css"

html
  font-size: 14px
.btn
  display: inline-block
  border: 0
  color: black
  height: 32px
  line-height: 32px
  background-color: rgb(244, 244, 244)
  padding: 0px 16px
  font-weight: 400
  text-decoration: none
  cursor: pointer
  &:hover
    background-color: rgb(234, 234, 234)
    text-decoration: none
    color: #23527c
  &:focus
    text-decoration: none
    color: black
    outline: none

  i
    vertical-align: bottom !important

.btn-sm
  height: 26px
  line-height: 26px
  padding: 0px 12px

.btn-primary
  color: white
  background-color: rgb(0, 120, 215)
  cursor: pointer

  &:hover,&.hover
    background-color: #005A9D
    color: white
  &:focus
    color: white
    background-color: rgb(1, 57, 102)
    outline: none

  &:disabled
    cursor: not-allowed
    opacity: .65

.btn-danger
  color: white
  background-color: rgb(215, 22, 26)

  &:hover
    background-color: rgb(191, 23, 26)
    color: white
    cursor: pointer
  &:focus
    color: white
    background-color: rgb(191, 23, 26)
    outline: none

  &:disabled
    cursor: not-allowed
    opacity: .65

.edit-buttons
  margin-top: 10px
  text-align: right
  a
    margin-left: 5px

.pull-right
  float: right!important

.form-separator
  background-color: #eaeaea
  margin-bottom: 5px
  padding: 5px
.panel-separator
  height: 8px

.centered-with-margins
  text-align: center
  margin-top: 20px !important
  margin-bottom: 20px !important

.w-simple-table
  font-size: 12px

  width: 100%
  tbody, thead
    tr
      td
        padding: 6px 0 6px 3px
    tbody
      tr
        &:hover
          background-color: #e5e5e5
    thead
      td
        font-weight: bold

.data-grid-container, .data-grid
  width: 100%
  background-color: white

  td
    padding: 0 !important

    tbody
      tr
        td:nth-child(1)
          width: 70px

    input
      margin: 0 !important
      height: 18px !important
      border: none !important

.darkred
  color: darkred
.darkgreen
  color: darkgreen
.center
  text-align: center
.button-field
  margin-top: 27px

.w-table
  table
    border-collapse: collapse
    border-spacing: 0
    outline: none
    position: relative
  > table
    border: solid 1px lightgrey
    width: 100%
    font-family: "Open Sans", sans-serif
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, .15)
    .w-table-center
      text-align: center
      > thead
        background-color: white
        > tr
          transition: color 2.25s
          > th
            border-bottom: solid 1px grey
            padding: 5px
            padding-right: 0
                  //font-weight: 500
            text-align: center
            border-right: 1px solid rgba(0, 0, 0, .1)
            font-size: 13px
            line-height: 1.42857
            color: #767676
            &:last-child
              border-right: solid 1px transparent
              > i
                margin-right: 10px
                &:hover
                  background-color: #e7e7e7
                  cursor: pointer

      > tbody
        color: #111111
        > tr
          border-collapse: collapse
          width: 100%
          > td
            font-family: Arial, Verdana, Tahoma
            border: 1px solid rgb(220, 220, 220)
            padding: 6px
            font-size: 12px
            border-collapse: collapse

            &:first-child
              border-left: none
              &:last-child
                border-left: none
          > tr.w-table-row-even

            background-color: white
          > tr.w-table-row-odd
            background-color: #f1f1f1
          > tr:hover > td
            background-color: #eaeaea
          > tr:nth-child(even) > td
            background-color: white
          > tr:nth-child(odd) > td
            background-color: #F7F7F7
          > tr:hover > td
            background-color: #eaeaea

      > tfoot
        > tr
          > td
            padding: 6px
            background-color: #F5F5F5
            padding-top: 0

.right
  text-align: right

.link
  &:hover
    color: #0078D7
    cursor: pointer

.link-color
  color: #0078D7
  text-decoration: none
  &:hover
    cursor: pointer
    color: #055595

.hover-pointer
  cursor: pointer

svg
  display: inline-block

.ag-watermark
  display: none !important
